import useRequest from 'core/hooks/useRequest';

import { rawAuthorsQuery } from 'core/queries/authorsPage';

import H1 from 'core/components/H1';
import { Indent } from 'core/components/Wrappers';
import AuthorsPage from 'core/components/AuthorsPage';

import ListPageLayout from 'site/components/ListPageLayout';

import {
  SMALL_INDENT,
} from 'site/constants';


function Authors(props) {
  const { data: rawAuthors, isLoading } = useRequest(rawAuthorsQuery(props));

  return (
    <ListPageLayout showRecommender>
      <H1>Авторы</H1>
      <Indent top={SMALL_INDENT} />
      <AuthorsPage rawAuthors={rawAuthors}  isLoading={isLoading} />
    </ListPageLayout>
  );
}

export default Authors;
