import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';
import { topicDateFormat } from 'core/utils/dates';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import Link from 'core/components/Link';
import Lightning from 'core/components/GameCompare/Lightning';

import styles from './index.styl';


const requiredPayloadFields = [
  'link',
  'headline',
  'listHeadline',
  'published_at',
];

const requiredPayloadImports = [
  'content',
];
const relationships = resolveRelationships(requiredPayloadImports, {}, {
  content: {},
});


function CompareCard(props) {
  const {
    content,
    theme: {
      colors,
      fonts,
    },
    isMobile,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    listHeadline,
    published_at: published,
  } = content.attributes;

  const {
    content: {
      widgets,
    },
  } = relationships(content);

  if (!widgets || widgets?.length === 0) return null;

  return (
    <div className={styles.card}>
      <style jsx>{`
        .${styles.card}
          background-color ${colors.card}

        .${styles.body}
          border-top 1px solid ${colors.divider}
          :global(.mobile) &
            border-top none

        .${styles.headline}
          color ${colors.primary}
          font 600 18px/25px ${fonts.text}
          :global(.mobile) &
            font 700 16px/20px ${fonts.text}

        .${styles.date}
          color ${colors.grey}
          font 14px/20px ${fonts.text}
      `}</style>
      <Link
        to={link}
        type='secondary'
        className={styles.wrapper}
      >
        <Lightning
          imageMaxWidth={isMobile ? 546 : 392}
          height={265}
          widget={widgets[0]}
          progressType={4}
          progress={100}
          borderRadius='0'
          lightningColor={colors.primary}
        />
        <div className={styles.body}>
          {(listHeadline || headline) && (
            <div className={styles.headline}>
              {listHeadline || headline}
            </div>
          )}
          <div className={styles.date}>{topicDateFormat(published)}</div>
        </div>
      </Link>
    </div>
  );
}

CompareCard.propTypes = {
  /** @ignore */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
};

const Card = withTheme(withBreakpoint(CompareCard));

Card.displayName = 'CompareCard';
Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export default Card;

export { CompareCard as StorybookComponent };
