import cx from 'classnames';

import styles from './index.styl';


function NewsSkeleton() {
  return (
    <>
      <div className={cx(styles.topic, 'lb-skeleton')} />
      <div className={cx(styles.topic, 'lb-skeleton')} />
      <div className={cx(styles.topic, 'lb-skeleton')} />
      <div className={cx(styles.topic, 'lb-skeleton')} />
    </>
  );
}

export default NewsSkeleton;
