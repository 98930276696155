import PropTypes from 'prop-types';

import H1 from 'core/components/H1';

import { rubricAttributes } from 'core/utils/prop-types/model';


export default function TitleComponent({ rawRubric }) {
  const {
    data: {
      attributes: {
        title,
        root_title: rootTitle,
      },
    },
  } = rawRubric;

  return (
    <H1 dataQa='page-title'>{`${title}${rootTitle !== title ? ' — ' + rootTitle : ''}`}</H1>
  );
}

TitleComponent.propTypes = {
  rawRubric: PropTypes.shape({
    data: PropTypes.shape({
      attributes: rubricAttributes,
    }),
  }),
};
