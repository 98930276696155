import { useContext } from 'react';

import StoryTopics from 'core/components/stories/StoryTopics';
import ContentBackground from 'core/components/ContentBackground';
import Shapka, { StickyContainer } from 'core/components/Shapka';
import Layout, { Header, Content, Footer } from 'core/components/Layout';
import { Mobile, Desktop } from 'core/components/breakpoint';
import { SideIndent } from 'core/components/Wrappers';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { GlobalTopicContext } from 'core/components/GlobalTopicProvider';

import Scooter from '../Scooter';

import {
  Billboard,
  ShowUp,
  PopUp as DesktopPopUp,
} from '../Ads/desktop';
import {
  TopBanner,
  PopUp as MobilePopUp,
} from '../Ads/mobile';


export default function SiteLayout({ children }) {
  const topic = useContext(GlobalTopicContext);

  const isPremiumTopic = topic?.attributes?.is_premium;

  return (
    <StickyContainer>
      <ContentBackground>
        <Layout>
          <Header>
            <Shapka
              isSecondShapkaForPremium={isPremiumTopic}
              fullwidth={isPremiumTopic}
              extra={(
                <>
                  <Desktop>
                    <DesktopPopUp />
                  </Desktop>
                  <Mobile>
                    <MobilePopUp />
                  </Mobile>
                </>
              )}
            />
          </Header>
          <Content>
            {!isPremiumTopic && (
              <AdWrapper bottom={20}>
                <Desktop>
                  <Billboard />
                  <ShowUp />
                </Desktop>

                <Mobile>
                  <TopBanner />
                </Mobile>
              </AdWrapper>
            )}
            {!isPremiumTopic && (
              <SideIndent>
                <StoryTopics />
              </SideIndent>
            )}
            {children}
          </Content>
          <Footer>
            <Scooter />
          </Footer>
        </Layout>
      </ContentBackground>
    </StickyContainer>
  );
}
