import useRequest from 'core/hooks/useRequest';

import {
  authorQuery,
  topicsQuery,
} from 'core/queries/authorPage';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { DESKTOP, MOBILE } from 'core/components/breakpoint/values';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import { filterRequiredParams } from 'core/utils/api';


import AuthorPage from 'core/components/AuthorPage';
import PageLoader from 'core/components/Loader/PageLoader';

import ListPageLayout from 'site/components/ListPageLayout';
import CardHorizontalDesktop, { CardHorizontalMobile } from 'site/cards/CardHorizontal';

const limit = 10;

const breakpointCards = {
  [DESKTOP]: CardHorizontalDesktop,
  [MOBILE]: CardHorizontalMobile,
};

const include = filterRequiredParams([CardHorizontalDesktop, CardHorizontalMobile], 'include');
const fields = filterRequiredParams([CardHorizontalDesktop, CardHorizontalMobile], 'fields');


function Author(props) {
  const {
    breakpoint,
  } = props;

  const results = useRequest({ queries: [
    authorQuery(props),
    topicsQuery({
      ...props,
      limit,
      include,
      fields,
      card: breakpointCards[breakpoint],
    }),
  ] });
  const [
    { data: author, isLoading: authorIsLoading },
    { data: topics, isLoading: topicsAreLoading },
  ] = results;

  return (
    <ListPageLayout showRecommender contextCount={1} >
      {authorIsLoading && <PageLoader />}
      <AuthorPage
        author={author}
        topics={topics}
        card={breakpointCards[breakpoint]}
        isLoading={topicsAreLoading}
      />
    </ListPageLayout>
  );
}

Author.propTypes = {
  breakpoint: breakpointPropTypes(),
};

export default withBreakpoint(Author);
