import useRequest from 'core/hooks/useRequest';

import { aboutQuery } from 'core/queries/about';

import AboutCore from 'core/components/About';


export default function AboutPage(props) {
  const { data, isLoading } = useRequest(aboutQuery(props));

  return <AboutCore content={data} isLoading={isLoading} />;
}
