import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';

import {
  tagQuery,
  topicsQuery,
} from 'core/queries/tagTopics';

import useRequest from 'core/hooks/useRequest';

import TagTopics from 'core/components/TagTopics';


import { getPage } from 'core/utils/url-helper';

import ListPageLayout from 'site/components/ListPageLayout';
import TopicsList from 'site/components/TopicsList';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import Skeleton from './Skeleton';


function TagPage({ location, history, match }) {
  const [
    { data: tag, isLoading: tagIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({ queries: [
    tagQuery({ history, match }),
    topicsQuery({
      location, history, match,
      limit: RUBRICS_PAGE_LIMIT,
      include: 'image,rubric',
    }),
  ] });

  if (tagIsLoading || topicsAreLoading) return <Skeleton />;

  return (
    <ListPageLayout showRecommender>
      <TagTopics
        tag={tag}
        topics={rawTopics}
        limit={RUBRICS_PAGE_LIMIT}
        isLoading={false}
      >
        {({ topics, title, descriptionMeta }) => {
          const page = getPage(location.search);
          const description = `${descriptionMeta || title} - все материалы по теме и новости технологий — Ferra.ru`;
          const descriptionSuffix = page > 1 ? ` - страница ${page}` : '';

          return (
            <>
              <Helmet>
                <meta
                  name='description'
                  content={description + descriptionSuffix}
                />
              </Helmet>
              <TopicsList topics={topics} />
            </>
          );
        }}
      </TagTopics>
    </ListPageLayout>
  );
}

TagPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default TagPage;
