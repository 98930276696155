import { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import themeHOC from 'core/components/theme';

import styles3 from './card3.styl'; // eslint-disable-line no-unused-vars
import styles5 from './card5.styl'; // eslint-disable-line no-unused-vars
import stylesHorizontal from './card_horizontal.styl'; // eslint-disable-line no-unused-vars

const NativeStyles = ({ theme, card }) => (
  <Fragment>
    <style global jsx>{`
      .native-ad.native-ad_card_${card}
        .ad_native_title
          font ${theme.texts.caption4.font}

        .ad_native_title_wrapper
          transition background-color ${theme.animations.hover}

        .ad_native_img
          &:after
            transition opacity ${theme.animations.hover}

        .ad_native_wrapper
          &:hover
            .ad_native_title
              color ${theme.colors.active1}

    `}</style>
  </Fragment>
);

NativeStyles.propTypes = {
  theme: PropTypes.object,
  card: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.number,
};

export default function cardStyles(Component) {
  return themeHOC(props => (
    <div className={cx(
      'native-ad',
      `native-ad_card_${props.card}`,
      props.type && `type${props.type}`
    )}
    >
      <NativeStyles {...props} />
      <Component {...props} />
    </div>
  ));
}
